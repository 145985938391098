class Utils {
  static getPrettyTimestamp(ms: number): string {
    // 1- Convert to seconds:
    var seconds = ms / 1000;
    // 2- Extract hours:
    var hours = Math.floor(seconds / 3600); // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    var minutes = seconds / 60; // 60 seconds in 1 minute
    const hoursStr = hours < 10 ? "0" + hours.toString() : hours.toString();
    const minutesStr =
      minutes < 10 ? "0" + minutes.toString() : minutes.toString();
    return hoursStr + ":" + minutesStr + ":00";
  }

  static getTwitchCompatiblePrettyTimestamp(minutes: number): string {
    // 2- Extract hours:
    var hours = Math.floor(minutes / 60); // 3,600 seconds in 1 hour
    //  0h0m0s
    return hours + "h" + (minutes % 60).toString() + "m00s";
  }

  static getTwitchCompatiblePrettyTimestampWithoutSeconds(minutes: number): string {
    // 2- Extract hours:
    var hours = Math.floor(minutes / 60); // 3,600 seconds in 1 hour
    //  0h0m0s
    return hours + "h" + (minutes % 60).toString() + "m";
  }
}

export default Utils;
