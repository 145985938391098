import Alert from "@material-ui/lab/Alert";
import { Chart, registerables } from "chart.js";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { TwitchPlayer } from "twitch-player";
import Utils from "../../utils";
import "./WatchPage.css";
import { initializeApp } from "firebase/app";
import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import { FIREBASE_CONFIG } from "../../FirebaseConfig";
import {
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { PogMoment } from "../HomePage/PogMoment";

Chart.register(...registerables);

type WatchPageProps = {} & RouteComponentProps<{
  id: string;
  timestamp?: string;
}>;

interface IState {
  noData: boolean;
  pogMoments: PogMoment[];
  seenMoments: Set<number>;
}

class WatchPage extends React.Component<WatchPageProps, IState> {
  videoId: string;
  initialTimestamp?: string;
  twitchPlayer?: TwitchPlayer;
  analytics: Analytics;
  secondsTimestamp: number[];

  constructor(props: WatchPageProps) {
    super(props);
    this.videoId = props.match.params.id;
    if (props.match.params.timestamp) {
      this.initialTimestamp = Utils.getTwitchCompatiblePrettyTimestamp(
        parseInt(props.match.params.timestamp)
      );
    }
    this.state = { noData: false, pogMoments: [], seenMoments: new Set() };
    const app = initializeApp(FIREBASE_CONFIG);

    this.analytics = getAnalytics(app);
    this.secondsTimestamp = [];
  }

  componentDidMount() {
    var chartXhr = new XMLHttpRequest();
    chartXhr.onload = () => {
      if (chartXhr.status === 404) {
        this.setState({
          noData: true,
          pogMoments: this.state.pogMoments,
          seenMoments: this.state.seenMoments,
        });
        return;
      }
      var blob = chartXhr.response;
      var data = new Map<string, string>(JSON.parse(blob));
      logEvent(this.analytics, "vod_loaded", { videoId: this.videoId });
      this.initChart(data);
    };
    const url = `https://firebasestorage.googleapis.com/v0/b/twitch-chat-highlights.appspot.com/o/videos%2F${this.videoId}?alt=media`;
    chartXhr.open("GET", url);
    chartXhr.send();

    var pogMomentXhr = new XMLHttpRequest();
    pogMomentXhr.onload = () => {
      if (pogMomentXhr.status === 404) {
        return;
      }
      var blob = pogMomentXhr.response;
      let parsedBlob: PogMoment[] = JSON.parse(blob) as PogMoment[];
      let result = parsedBlob;
      result.sort((a, b) => b.score - a.score);
      this.setState({
        noData: this.state.noData,
        pogMoments: result,
        seenMoments: this.state.seenMoments,
      });
    };
    pogMomentXhr.open(
      "GET",
      `https://firebasestorage.googleapis.com/v0/b/twitch-chat-highlights.appspot.com/o/pog_moments_by_video%2F${this.videoId}?alt=media`
    );
    pogMomentXhr.send();

    this.twitchPlayer = TwitchPlayer.FromOptions("twitch-player", {
      width: "100%",
      height: "100%",
      video: this.videoId,
      autoplay: true,
      time: this.initialTimestamp,
    });
  }

  getCurrentTimeInMinutes(): number {
    const currentTime =
      this.twitchPlayer === undefined ? 0 : this.twitchPlayer.getCurrentTime();
    return currentTime === undefined ? 0 : currentTime / 60;
  }

  resolveChartColor(dataIndex: number): string {
    if (
      this.secondsTimestamp[dataIndex] / 60 <
      this.getCurrentTimeInMinutes()
    ) {
      return "rgb(100, 65, 165)";
    } else {
      return "rgb(223, 223, 223)";
    }
  }

  render() {
    return (
      <>
        <Grid container>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <div style={{ height: "70vh" }} id="twitch-player"></div>
            <canvas height="50" id="chart"></canvas>
            {this.state.noData && (
              <Alert severity="error">
                This VOD is not supported. This means that the streamer is
                either not available on POG VODS or the VOD data has not yet
                updated.
              </Alert>
            )}
          </Grid>
          <Grid item xs={2} className="pogMomentTable">
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Seen</TableCell>
                  <TableCell>Timestamp</TableCell>
                  <TableCell>Pog Score</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.pogMoments.map((row, index) => {
                  const timestamp =
                    Utils.getTwitchCompatiblePrettyTimestampWithoutSeconds(
                      parseInt(row.time)
                    );
                  return (
                    <TableRow
                      className="pogMomentRow"
                      hover={true}
                      onClick={() => {
                        if (this.twitchPlayer) {
                          const newSeenMoments = new Set(
                            this.state.seenMoments
                          );
                          newSeenMoments.add(index);
                          this.setState({
                            noData: this.state.noData,
                            pogMoments: this.state.pogMoments,
                            seenMoments: newSeenMoments,
                          });
                          this.twitchPlayer.seek(parseInt(row.time) * 60);
                        }
                      }}
                    >
                      <TableCell>
                        <Checkbox
                          color="primary"
                          checked={this.state.seenMoments.has(index)}
                        />
                      </TableCell>
                      <TableCell>{timestamp}</TableCell>
                      <TableCell>{row.score.toFixed(0)}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </>
    );
  }

  initChart(countByTime: Map<string, string>) {
    let values: number[] = [];
    let labels: string[] = [];
    let secondsTimestamp: number[] = [];
    countByTime.forEach((value: string, key: string) => {
      labels.push(Utils.getPrettyTimestamp(parseInt(key) * 60 * 1000));
      values.push(parseInt(value));
      secondsTimestamp.push(parseInt(key) * 60);
    });
    this.secondsTimestamp = secondsTimestamp;
    const chartEl = document.getElementById("chart");

    const highlightsChart = new Chart(chartEl, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            barPercentage: 1.0,
            categoryPercentage: 1.0,
            data: values,
            borderWidth: 1,
            backgroundColor: (context) => {
              return this.resolveChartColor(context.dataIndex);
            },
            borderColor: (context) => {
              return this.resolveChartColor(context.dataIndex);
            },
            hoverBackgroundColor: "orange",
            hoverBorderColor: "orange",
          },
        ],
      },
      options: {
        interaction: {
          intersect: false,
        },
        onClick: (event) => {
          var nativeEvent = event.native;
          if (nativeEvent === null) {
            return;
          }
          const points = highlightsChart.getElementsAtEventForMode(
            nativeEvent,
            "nearest",
            { intersect: false },
            true
          );

          if (points.length) {
            const index = points[0].index;
            if (this.twitchPlayer) {
              logEvent(this.analytics, "graph_clicked");
              this.twitchPlayer.seek(secondsTimestamp[index]);
              this.twitchPlayer.play();
            }
          }
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              beforeTitle: () => "Click to go to ",
              afterLabel: () => " messages",
            },
            intersect: false,
          },
        },
        scales: {
          x: {
            display: true,
            ticks: {
              autoSkip: false,
              callback: function (value, index, values) {
                if (index === 0 || index === labels.length - 1) {
                  return labels[index];
                } else {
                  return "";
                }
              },
            },
            grid: {
              display: false,
            },
          },
          y: {
            display: false,
          },
        },
      },
    });

    setInterval(() => {
      highlightsChart.update("none");
    }, 1000);
  }
}

export default WatchPage;
