import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import WatchPage from "./pages/WatchPage/WatchPage";
import HomePage from "./pages/HomePage/HomePage";
import {
  AppBar,
  Divider,
  Grid,
  Link,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React from "react";
import ChannelPage from "./pages/ChannelPage/ChannelPage";

declare var adsbygoogle: any;

class App extends React.Component {
  componentDidMount() {
    // (adsbygoogle = (window as any).adsbygoogle || []).push({});
  }
  render() {
    return (
      <div className="App">
        <AppBar style={{ background: "#708090" }} position="static">
          <Toolbar variant="dense">
            <Link
              className="header-text"
              href="/"
              variant="h6"
              color="inherit"
              underline="none"
            >
              POG VODS
            </Link>
          </Toolbar>
        </AppBar>
        {/* <Grid container spacing={3}>
          <Grid item xs={2}>
          </Grid>
          <Grid item xs={8}> */}
            <Router>
              <Switch>
                <Route path="/watch/:id/:timestamp?" component={WatchPage} />
                <Route path="/channel/:channel" component={ChannelPage} />
                <Route path="/" component={HomePage} />
              </Switch>
            </Router>
            <Divider/>
            <Typography>© 2021 POG VODS</Typography>
          {/* </Grid>
          <Grid item xs={2}>
          </Grid>
        </Grid> */}
      </div>
    );
  }
}

export default App;
