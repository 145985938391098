import { initializeApp } from "@firebase/app";
import { getDatabase, onValue, ref } from "firebase/database";
import React from "react";
import "./HomePage.css";
import SearchIcon from "@material-ui/icons/Search";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {
  Container,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import Image from "material-ui-image";
import { PogMoment } from "./PogMoment";
import { TopVideoMetadata } from "./TopVideoMetadata";
import Utils from "../../utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FIREBASE_CONFIG } from "../../FirebaseConfig";

const URL_REGEX = /videos\/([0-9]+)/;

interface IState {
  topVods: TopVideoMetadata[];
  topMoments: PogMoment[];
  channels: string[];
}

class HomePage extends React.Component<RouteComponentProps, IState> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = { topVods: [], topMoments: [], channels: [] };
  }

  componentDidMount() {
    var topVodXhr = new XMLHttpRequest();
    topVodXhr.onload = () => {
      if (topVodXhr.status === 404) {
        return;
      }
      var blob = topVodXhr.response;
      let parsedBlob: TopVideoMetadata[] = JSON.parse(
        blob
      ) as TopVideoMetadata[];
      let result = parsedBlob
        .filter((v) => v.thumbnailUrl.length > 0)
        .slice(0, 10);
      this.setState({
        topVods: result,
        topMoments: this.state.topMoments,
        channels: this.state.channels,
      });
    };
    topVodXhr.open(
      "GET",
      `https://firebasestorage.googleapis.com/v0/b/twitch-chat-highlights.appspot.com/o/top_pog_videos?alt=media`
    );
    topVodXhr.send();

    const topMomentXhr = new XMLHttpRequest();
    topMomentXhr.onload = () => {
      if (topMomentXhr.status === 404) {
        return;
      }
      var blob = topMomentXhr.response;
      let parsedBlob: PogMoment[] = JSON.parse(blob) as PogMoment[];
      let result = parsedBlob;
      this.setState({
        topVods: this.state.topVods,
        topMoments: result,
        channels: this.state.channels,
      });
    };
    topMomentXhr.open(
      "GET",
      `https://firebasestorage.googleapis.com/v0/b/twitch-chat-highlights.appspot.com/o/top_pog_moments?alt=media`
    );
    topMomentXhr.send();

    const app = initializeApp(FIREBASE_CONFIG);
    const db = getDatabase(app);
    onValue(
      ref(db, "/channels/"),
      (snapshot) => {
        const channels: string[] = [];
        snapshot.forEach((data) => {
          if (data.key !== null) {
            channels.push(data.key);
          }
        });
        this.setState({
          topVods: this.state.topVods,
          topMoments: this.state.topMoments,
          channels: channels,
        });
      },
      {
        onlyOnce: true,
      }
    );
  }

  handleChannelSubmit(event: React.SyntheticEvent): void {
    event.preventDefault();

    const target = event.target as typeof event.target & {
      channel: { value: string };
    };
    const lowerCaseChannel = target.channel.value.toLowerCase();
    this.props.history.push("/channel/" + lowerCaseChannel);
  }

  handleVodSubmit(event: React.SyntheticEvent): void {
    event.preventDefault();
    const target = event.target as typeof event.target & {
      videoUrl: { value: string };
    };
    const match = target.videoUrl.value.match(URL_REGEX);
    if (!match || match.length < 2) {
      alert("Invalid video url");
    } else {
      this.props.history.push("/watch/" + match[1]);
    }
  }

  sendToWatchFromMoment(videoId: string, timestamp: string) {
    this.props.history.push("/watch/" + videoId + "/" + timestamp);
  }

  render() {
    return (
      <Container>
        <Paper elevation={3}>
          <img className="landingImage" src="/graph_v2.png" alt="logo" />
          <Typography variant="h5">
            Improve your twitch.tv VOD viewing experience by knowing where
            <br />
            chat spams the most!
          </Typography>

          <form
            noValidate
            autoComplete="off"
            onSubmit={(event) => this.handleChannelSubmit(event)}
          >
            <Grid className="inputForm" container>
              <Grid item xs={5}></Grid>
              <Grid item xs={2}>
                <Autocomplete
                  options={this.state.channels}
                  renderInput={(params) => (
                    <TextField {...params} label="Channel" name="channel" />
                  )}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton type="submit" aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Grid>
            </Grid>
          </form>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="h4">Recent Top Pog VODs</Typography>
              {this.state.topVods.length === 0 && (
                <LinearProgress className="progressBar" />
              )}
              <ImageList rowHeight={220} cols={1} gap={0}>
                {this.state.topVods.map((vod, index) => {
                  let imgUrl = vod.thumbnailUrl;
                  imgUrl = imgUrl.replace("%{width}", "640");
                  imgUrl = imgUrl.replace("%{height}", "360");
                  const url = "watch/" + vod.id;
                  const title =
                    "Rank " + (index + 1).toString() + ": " + vod.title;
                  return (
                    <ImageListItem key={vod.id}>
                      <Link to={url}>
                        <Image src={imgUrl} alt={title} aspectRatio={16 / 9} />
                        <ImageListItemBar
                          title={title}
                          subtitle={<span>Channel: {vod.channel}</span>}
                        />
                      </Link>
                    </ImageListItem>
                  );
                })}
              </ImageList>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h4">Recent Top Pog Moments</Typography>
              {this.state.topMoments.length === 0 && (
                <LinearProgress className="progressBar" />
              )}
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Rank</TableCell>
                    <TableCell>Channel</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Timestamp</TableCell>
                    <TableCell>Pog Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.topMoments.map((row, index) => {
                    const timestamp =
                      Utils.getTwitchCompatiblePrettyTimestampWithoutSeconds(
                        parseInt(row.time)
                      );
                    const creationDateStr = new Date(
                      row.creationDateMs
                    ).toDateString();
                    return (
                      <TableRow
                        className="pogMomentRow"
                        hover={true}
                        onClick={() => {
                          this.sendToWatchFromMoment(row.videoId, row.time);
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell>{row.channel}</TableCell>
                        <TableCell>{creationDateStr}</TableCell>
                        <TableCell>{timestamp}</TableCell>
                        <TableCell>{row.score.toFixed(0)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }
}
export default withRouter(HomePage);
