
export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyDM8BdpL-_9lcGg_PdgLifIrlwf-zNwOtQ",
    authDomain: "twitch-chat-highlights.firebaseapp.com",
    databaseURL: "https://twitch-chat-highlights-default-rtdb.firebaseio.com",
    projectId: "twitch-chat-highlights",
    storageBucket: "twitch-chat-highlights.appspot.com",
    messagingSenderId: "812593609827",
    appId: "1:812593609827:web:c817924e8d002aa0e70c56",
    measurementId: "G-X7ZF5XTV9S",
  };