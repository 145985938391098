import { initializeApp } from "@firebase/app";
import { getDatabase, onValue, ref } from "firebase/database";
import {
  Container,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  LinearProgress,
  Paper,
  Typography,
} from "@material-ui/core";
import Image from "material-ui-image";
import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import "./ChannelPage.css";
import { Alert } from "@material-ui/lab";
import { FIREBASE_CONFIG } from "../../FirebaseConfig";
import { getAnalytics, logEvent } from "@firebase/analytics";

type ChannelPageProps = {} & RouteComponentProps<{ channel: string }>;

interface IState {
  vods: VodPreview[];
  noData: boolean;
}

class ChannelPage extends React.Component<ChannelPageProps, IState> {
  channel: string;

  constructor(props: ChannelPageProps) {
    super(props);
    this.channel = props.match.params.channel;
    this.state = { vods: [], noData: false };
  }

  componentDidMount() {
    const app = initializeApp(FIREBASE_CONFIG);
    const db = getDatabase(app);
    const analytics = getAnalytics(app);
    logEvent(analytics, "channel_selected", { channel: this.channel });
    onValue(
      ref(db, "/channel_to_vod/" + this.channel),
      (snapshot) => {
        if (snapshot.size === 0) {
          this.setState({ vods: this.state.vods, noData: true });
          return;
        }
        const vodsToAdd: VodPreview[] = [];
        snapshot.forEach((data) => {
          if (data.key !== null) {
            vodsToAdd.push(
              new VodPreview(
                data.key,
                data.val().title,
                parseInt(data.val().creationDate),
                data.val().thumbnailUrl
              )
            );
          }
        });
        const sortedVodsToAdd = vodsToAdd.sort(
          (n1, n2) => n2.creationDate - n1.creationDate
        );
        this.setState({ vods: sortedVodsToAdd, noData: this.state.noData });
      },
      {
        onlyOnce: true,
      }
    );
  }

  render() {
    return (
      <Container>
        <Paper elevation={3}>
          <Typography variant="h4">Recent VODs for {this.channel}</Typography>
          {this.state.noData && (
            <Alert severity="error">
              This channel has no VODS. This means that the streamer is either
              not available on POG VODS or the VOD data has not yet updated.
            </Alert>
          )}
          {this.state.vods.length === 0 && !this.state.noData && (
            <LinearProgress className="progressBar" />
          )}
          <ImageList  className="vodsList"  rowHeight={220} cols={3} gap={0}>
            {this.state.vods.map((vod) => {
              let imgUrl = vod.thumbnailUrl;
              imgUrl = imgUrl.replace("%{width}", "640");
              imgUrl = imgUrl.replace("%{height}", "360");
              const url = "/watch/" + vod.id;
              const date = new Date(vod.creationDate).toDateString();
              return (
                <ImageListItem key={vod.id}>
                  <Link to={url}>
                    <Image src={imgUrl} alt={vod.title} aspectRatio={16 / 9} />
                    <ImageListItemBar
                      title={vod.title}
                      subtitle={<span>{date}</span>}
                    />
                  </Link>
                </ImageListItem>
              );
            })}
          </ImageList>
        </Paper>
      </Container>
    );
  }
}

class VodPreview {
  id: string;
  title: string;
  creationDate: number;
  thumbnailUrl: string;

  constructor(
    id: string,
    title: string,
    creationDate: number,
    thumbnailUrl: string
  ) {
    this.id = id;
    this.title = title;
    this.creationDate = creationDate;
    this.thumbnailUrl = thumbnailUrl;
  }
}

export default ChannelPage;
